<!-- Version 1.0.2 -->
<template>
  <div v-on="$listeners" class="fp-icon-container">
    <div
      class="fp-icon-svg"
      :class="'icon-' + icon"
      :style="
        (color ? 'background:' + color + ';' : '') +
        (size ? 'width:' + size + 'px;height:' + size + 'px;' : '')
      "
    ></div>
  </div>
</template>
<script>
export default {
  name: "SvgIcon",
  props: {
    icon: String,
    color: String,
    size: Number,
  },
};
</script>
<style>
@import "../Assets/SvgIcons/Operations/_operations.css";
@import "../Assets/SvgIcons/Item/_item.css";
@import "../Assets/SvgIcons/User/_user.css";
@import "../Assets/SvgIcons/Logo/_logo.css";

.fp-icon-container {
  margin: 10px 10px auto 0px;
  display: inline-block;
  background: var(--eh-primary-5);
  border-radius: 10px;
}

.fp-icon-svg {
  width: 40px;
  height: 40px;
  margin: 5px;
  background-color: white;
}
</style>
